import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0ae74b20&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0ae74b20&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae74b20",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Template: require('/devcloud/slavespace/slave1-new/workspace/j_52qd1tlN/src/components/FilterSearch/template/index.js').default})
